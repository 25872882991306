/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import Workflow from './models/Workflow';
import Upload from './models/Upload';
import CreateAndValidate from './models/CreateAndValidate';
import CmMetadata from './models/CmMetadata';
import TitleActions from './models/TitleActions';
import BusinessUnit from './services/workflow/BusinessUnitService';
import WorkflowHistoryService from './services/workflow/WorkflowHistoryService';
import WorkflowSearchService from './services/workflow/WorkflowSearchService';
import BucketFoldersService from './services/upload/BucketFoldersService';
import CreateBucketFoldersService from './services/upload/CreateBucketFoldersService';
import DeleteBucketFilesService from './services/upload/DeleteBucketFilesService';
import PreviewService from './services/workflow/PreviewService';
import PreviewVersionService from './services/workflow/PreviewVersionService';
import CreateService from './services/create/CreateService';
import ValidateService from './services/create/ValidateService';
import CmSubBusinessService from './services/metaData/CmSubBusinessService';
import CmDirectoryService from './services/metaData/CmDirectoryService';
import TitleDerivativeSubBusinessService from './services/titleAction/TitleDerivativeSubBusinessService';
import TitleDerivativeService from './services/titleAction/TitleDerivativeService';
import TitleDerivativeDirectoryService from './services/titleAction/TitleDerivativeDirectoryService';
import TitleActionBackupService from './services/titleAction/TitleActionBackupService';
import TitleActionUploadService from './services/titleAction/TitleActionUploadService';
import TitleActionExportService from './services/titleAction/TitleActionExportService';
import TitleActionUpdfService from './services/titleAction/TitleActionUpdfService';
import TitleDerivativeVerifyService from './services/titleAction/TitleDerivativeVerifyService';
import IngestionCancelService from './services/workflow/IngestionCancelService';
import FetchDBFlagService from './services/workflow/FetchDBFlagService';
// import CmDerivativeService from './services/metaData/CmDerivativeService';
// import ContentManagerContainer from './ContentManagerContainer';
import ContentManagerContainer from './ContentManagerContainer';
import IngestionResourceService from './services/create/IngestionResourceService';
// import IngestResource from './models/IngestResource';

export default {
  path: ['/cm/ingestion/workflow', '/cm/ingestion/create', '/cm/ingestion/title', '/cm/ingestion/uPDF'],
  exact: true,
  services: [
    new BusinessUnit(),
    new WorkflowHistoryService(),
    new WorkflowSearchService(),
    new BucketFoldersService(),
    new CreateBucketFoldersService(),
    new DeleteBucketFilesService(),
    new PreviewService(),
    new PreviewVersionService(),
    new CreateService(),
    new ValidateService(),
    new CmSubBusinessService(),
    new CmDirectoryService(),
    new TitleDerivativeSubBusinessService(),
    new TitleDerivativeService(),
    new TitleDerivativeDirectoryService(),
    new TitleActionBackupService(),
    new TitleActionUploadService(),
    new TitleActionExportService(),
    new TitleActionUpdfService(),
    new TitleDerivativeVerifyService(),
    new IngestionCancelService(),
    new FetchDBFlagService(),
    new IngestionResourceService()
  ],

  stores: [
    {
      name: 'workflow',
      spec: Workflow,
      saveLocally: false,
      initialState: {
        // workflowPostData: [],
        contentManagerState: []
      }
    },
    {
      name: 'upload',
      spec: Upload,
      saveLocally: false,
      initialState: {
        bucketFolders: []
      }
    },
    {
      name: 'createAndValidate',
      spec: CreateAndValidate,
      saveLocally: false,
      initialState: {
        checkBoxes: []
      }
    },
    {
      name: 'cmmetaData',
      spec: CmMetadata,
      saveLocally: false,
      initialState: {
        subBusiness: []
      }
    },
    {
      name: 'titleActionData',
      spec: TitleActions,
      saveLocally: false,
      initialState: {
        subBusiness: []
      }
    }
    // {
    //   name: 'ingestResource',
    //   spec: IngestResource,
    //   saveLocally: false,
    //   initialState: {
    //     checkBoxes: []
    //   }
    // }
  ],

  render: (props) => (
    <ContentManagerContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
