import Typography from '@material-ui/core/Typography/Typography';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import Popover from '@mui/material/Popover';
import { inject, observer } from 'mobx-react';
// import { Pagination, Stack, Table } from '@mui/material';
import Framework, { shapes } from '@greenville/framework';
import {
  Box,
  Button,
  Checkbox,
  Radio,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles
} from '@material-ui/core';
import { Stack, Table } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { toJS } from 'mobx';
import * as constants from '../../../../common/constants';
import ExportToCSV from '../../common/components/ExportToCSV';
import ChatUtilityGetTypesModel from '../../models/ChatUtilityGetTypesModel';
import ChatgptUtilityIESUser from '../../models/ChatgptIESUser';
import ChatgptUtilityMapping from '../../models/ChatgptMapping';
import ResponseModel from '../../models/Response';
import ChatDialogComponent from './ChatDialogComponent';
import InternalUsersList from '../../models/InternalUsersList';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = () => ({
  tableCustomWidth: {
    width: '2px'
  },
  tableDateWidth: {
    width: '190px'
  },
  tableBookWidth: {
    width: '160px'
  },
  tableTitleWidth: {
    width: '150px'
  },
  tableFeedWidth: {
    width: '140px'
  },
  tableReasonWidth: {
    width: '200px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableTypeWidth: {
    width: '100px'
  },
  tableUserWidth: {
    width: '160px'
  },
  tableUserIdWidth: {
    width: '100px'
  },
  tableFilterDateWidth: {
    width: '190px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterFeedWidth: {
    width: '150px'
  },
  tableFilterReasonWidth: {
    width: '180px'
  },
  tableFilterTooltipWidth: {
    width: '100px'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  tableTypeText: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  radioButton: {
    textAlign: 'center'
  },
  buttonStyle: {
    cursor: 'pointer',
    width: '170px',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  },
  root: {
    width: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  tableTextAlongWidth: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: 160
  },
  columnWidth: {
    width: 200
  },
  contentText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

/* eslint-disable no-shadow */
function ChatDataGrid(props) {
  const {
    chatgptUtilityData,
    ChatgptUtilityIESUserData,
    chatgptMappingUtility,
    classes,
    handleSearch,
    isNextPageKey,
    isFeedback,
    handleAscending,
    chatgptResponseUtility,
    chatgptUtilityResponseStatus,
    chatUtilityGetTypes,
    isNextPageKeyEnable,
    setUserId,
    userIdChecked,
    isAudioIntentSelected,
    isUserIdExcluded,
    internalUsers
  } = props;
  const [filteredArray, setFilteredArray] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [userTocDetails, setUserTocDetails] = useState({});
  const [openChat, openChatDialog] = useState(false);
  const [loaderStatusResponse] = useState(true);
  const [dataValue, setDataValue] = useState({});
  const [dataLength, setDatalength] = useState({});
  const [directionValue, setDirectionValue] = useState('');
  const [rowDialogue, setRowDialogue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState('');
  const [problemSolveFlow, setProblemSolveFlow] = useState('');
  const [order, SetOrder] = useState('ASC');
  const internalUserIds = JSON.parse(JSON.stringify(toJS(internalUsers)));

  useEffect(() => {
    Framework.getEventManager().on(constants.SET_CHATGPT_TYPE_RESPONSE_DATA, async () => {
      const { data } = chatUtilityGetTypes;
      const typesData = data.map((item) => item.type);
      if (!isFeedback) {
        if (data) {
          if (typesData.includes(constants.PROBLEM_SOLVE_VERIFIER)) {
            setProblemSolveFlow(constants.VERIFIER_FLOW);
          } else if (typesData.includes(constants.GENERATE_PYTHON)) {
            setProblemSolveFlow(constants.GENERATE_PYTHON_FLOW);
          } else {
            setProblemSolveFlow('');
          }
        }
      } else if (isFeedback) {
        let typeValue;
        if (typesData.includes(constants.SEARCH_OVERVIEW_TYPE)) {
          typeValue = constants.SEARCH_OVERVIEW_TYPE;
        } else if (typesData.includes(constants.EXPLAIN_TYPE)) {
          typeValue = constants.EXPLAIN_TYPE;
        } else if (typesData.includes(constants.SUMMARY_TYPE)) {
          typeValue = constants.SUMMARY_TYPE;
        } else if (typesData.includes(constants.QUIZ_MCQ_TYPE)) {
          typeValue = constants.QUIZ_MCQ_TYPE;
        } else if (typesData.includes(constants.IMAGE_COPILOT_EXPLAIN)) {
          typeValue = constants.IMAGE_COPILOT_EXPLAIN;
        } else if (typesData.includes(constants.IMAGE_COPILOT_CYU_EVALUATE)) {
          typeValue = constants.IMAGE_COPILOT_CYU_EVALUATE;
        } else if (typesData.includes(constants.IMAGE_COPILOT_ANSWER)) {
          typeValue = constants.IMAGE_COPILOT_ANSWER;
        }
        const fetchResult = data?.find((item) => item.type === typeValue);
        if (typeValue && isFeedback && fetchResult) {
          const { bookId, requestId } = fetchResult;
          await chatgptResponseUtility.fetch({ requestId, bookId, type: typeValue });
        }
      }
    });
  }, []);

  useEffect(() => {
    const data = chatgptUtilityData && Array.isArray(chatgptUtilityData) && chatgptUtilityData;
    if (isUserIdExcluded && internalUserIds?.userId.length > 0) {
      const filteredData = data.filter((item) => !internalUserIds?.userId.includes(item.userId));
      setFilteredArray(filteredData);
      setDatalength(filteredData.length);
    } else {
      setFilteredArray(data);
      setDatalength(data.length);
    }

    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });
    Framework.getEventManager().on(constants.SET_CHATGPT_MAPPING_DATA, () => {
      const { toc } = chatgptMappingUtility;
      if (toc.length > 0) {
        setUserTocDetails(toc);
      }
    });
    let rowDialogProcessed = {};
    let rowProcessed = {};
    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const {
        summary,
        quiz,
        discuss,
        chat_enrichment,
        chat_intent,
        content_classifier,
        flashcards,
        topic,
        summary_enrichment,
        summaryTopic,
        quizValidateIntent,
        quizIntent,
        mcq,
        mcqMisconceptions,
        mcqSkillIdentifier,
        mcqQuestionGeneration,
        quizMcq,
        quizFreeResponse,
        freeResponse,
        mcqDistract,
        dictionarySummary,
        dictionaryQuiz,
        content_filter,
        explainStandalone,
        salutation,
        contentIdentifierUserSelection,
        problemSolve,
        summaryExplain,
        answerCyuMcq,
        problemSolveRectifier,
        expertDeskFreeResponse,
        expertDeskMcq,
        commonAuditData,
        commonIntentIdentifierData
      } = chatgptResponseUtility;
      if (chatgptResponseUtility) {
        const dictionarySummaryData = dictionarySummary && dictionarySummary.length > 0 ? dictionarySummary : [];
        const dictionaryQuizData = dictionaryQuiz && dictionaryQuiz.length > 0 ? dictionaryQuiz : [];
        const summaryData = summary && summary.length > 0 ? summary : [];
        const quizData = quiz && quiz.length > 0 && quiz ? quiz : [];
        const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
        const chatEnrichmentData = chat_enrichment && chat_enrichment.length > 0 ? chat_enrichment : [];
        const chatIntentData = chat_intent && chat_intent.length > 0 ? chat_intent : [];
        const flashcardsData = flashcards && flashcards.length > 0 ? flashcards : [];
        const topicData = topic && topic.length > 0 ? topic : [];
        const summaryEnrichmentData = summary_enrichment && summary_enrichment.length > 0 ? summary_enrichment : [];
        const summaryTopicData = summaryTopic && summaryTopic.length > 0 ? summaryTopic : [];
        const contentClassifier = content_classifier && content_classifier.length > 0 ? content_classifier : [];
        const quizValidateData = quizValidateIntent && quizValidateIntent.length > 0 ? quizValidateIntent : [];
        const quizIintentData = quizIntent && quizIntent.length > 0 ? quizIntent : [];
        const mcqData = mcq && mcq.length > 0 ? mcq : [];
        const mcqMisconceptionsData = mcqMisconceptions && mcqMisconceptions.length > 0 ? mcqMisconceptions : [];
        const mcqSkillIdentifierData = mcqSkillIdentifier && mcqSkillIdentifier.length > 0 ? mcqSkillIdentifier : [];
        const mcqQuestionGenerationData =
          mcqQuestionGeneration && mcqQuestionGeneration.length > 0 ? mcqQuestionGeneration : [];
        const quizMcqData = quizMcq && quizMcq.length > 0 ? quizMcq : [];
        const quizFreeResponseData = quizFreeResponse && quizFreeResponse.length > 0 ? quizFreeResponse : [];
        const freeResponseData = freeResponse && freeResponse.length > 0 ? freeResponse : [];
        const mcqDistractData = mcqDistract && mcqDistract.length > 0 ? mcqDistract : [];
        const contentFilterData = content_filter && content_filter.length > 0 ? content_filter : [];
        const explainStandaloneData = explainStandalone && explainStandalone.length > 0 ? explainStandalone : [];
        const salutationData = salutation && salutation.length > 0 ? salutation : [];
        const contentIdentifierUserSelectionData =
          contentIdentifierUserSelection && contentIdentifierUserSelection.length > 0
            ? contentIdentifierUserSelection
            : [];
        const problemSolveData = problemSolve && problemSolve.length > 0 ? problemSolve : [];
        const summaryExplainData = summaryExplain && summaryExplain.length > 0 ? summaryExplain : [];
        const answerCyuMcqData = answerCyuMcq && answerCyuMcq.length > 0 ? answerCyuMcq : [];
        const problemSolveRectifierData =
          problemSolveRectifier && problemSolveRectifier.length > 0 ? problemSolveRectifier : [];
        const expertDeskFreeResponseData =
          expertDeskFreeResponse && expertDeskFreeResponse.length > 0 ? expertDeskFreeResponse : [];
        const expertDeskMcqData = expertDeskMcq && expertDeskMcq.length > 0 ? expertDeskMcq : [];
        const commonAuditDataVal = commonAuditData && commonAuditData.length > 0 ? commonAuditData : [];
        const commonIntentIdentifierDataVal =
          commonIntentIdentifierData && commonIntentIdentifierData.length > 0 ? commonIntentIdentifierData : [];
        const combineData = [
          ...commonAuditDataVal,
          ...expertDeskFreeResponseData,
          ...expertDeskMcqData,
          ...problemSolveRectifierData,
          ...problemSolveData,
          ...contentIdentifierUserSelectionData,
          ...salutationData,
          ...explainStandaloneData,
          ...dictionarySummaryData,
          ...dictionaryQuizData,
          ...summaryData,
          ...discussData,
          ...quizData,
          ...chatEnrichmentData,
          ...chatIntentData,
          ...flashcardsData,
          ...topicData,
          ...contentClassifier,
          ...summaryEnrichmentData,
          ...summaryTopicData,
          ...quizValidateData,
          ...quizIintentData,
          ...mcqData,
          ...mcqMisconceptionsData,
          ...mcqSkillIdentifierData,
          ...mcqQuestionGenerationData,
          ...quizMcqData,
          ...quizFreeResponseData,
          ...freeResponseData,
          ...mcqDistractData,
          ...contentFilterData,
          ...summaryExplainData,
          ...answerCyuMcqData,
          ...commonIntentIdentifierDataVal
        ];
        /* eslint-disable no-param-reassign */
        if (combineData && combineData[0] && combineData[0].type === 'chat_intent') {
          try {
            combineData[0].botResponse = JSON.parse(combineData[0].botResponse);
          } catch (err) {
            const { botResponse } = combineData[0];
            combineData[0] = { ...combineData[0], botResponse: botResponse[0] };
          }
        }
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        // const { prompt } = combineDatatoJS[0];
        if (combineDatatoJS[0] && Object.keys(combineDatatoJS[0]).length > 0) {
          rowProcessed = Object.entries(combineDatatoJS[0]).reduce((obj, [key, value]) => {
            if (key !== null && value !== null) {
              obj[key] = value;
            }
            return obj;
          }, {});
          if (rowProcessed.serverDateTime && isFeedback) {
            delete rowProcessed.serverDateTime;
          }
          if (rowDialogue && Object.keys(rowDialogue).length > 0) {
            rowDialogProcessed = Object.entries(rowDialogue).reduce((obj, [key, value]) => {
              if (key !== null && value !== null) {
                obj[key] = value;
              }
              return obj;
            }, {});
          }

          if (rowProcessed.type === 'chat_intent') {
            const { botResponse } = rowProcessed;
            let processBotResponse = '';
            try {
              processBotResponse = [JSON.parse(botResponse)];
            } catch (err) {
              const splitTwoText = botResponse.split('[BAD-GPT]');
              const normGPT = JSON.parse(splitTwoText[0].split('[NORM-GPT]')[1]);
              const badGPT = JSON.parse(splitTwoText[1]);
              processBotResponse = [normGPT, badGPT];
            }
            rowProcessed.botResponse = processBotResponse;
          }
          if (rowProcessed.type === constants.CONTEXT_IDENTIFIER_USER_SELECTION) {
            const { botResponse } = rowProcessed;
            rowProcessed.botResponse = [botResponse];
          }

          const rowChangedValue = {
            ...rowDialogProcessed,
            ...rowProcessed
          };

          if (isFeedback) {
            rowChangedValue.feedBackType = rowDialogProcessed.type || rowProcessed?.type;
          }

          /* eslint-enable camelcase */
          setDataValue(rowChangedValue);
        }
      }
    });
  }, [
    chatgptUtilityData,
    isNextPageKey,
    chatgptResponseUtility,
    rowDialogue,
    userEmailDetails,
    userTocDetails,
    ChatgptUtilityIESUserData,
    chatUtilityGetTypes,
    isNextPageKeyEnable,
    isUserIdExcluded
  ]);

  useEffect(() => {
    if (dataValue?.bookId && dataValue?.requestId && !isFeedback) {
      const typeValue = dataValue?.type;
      const url = `${window.location.origin}${window.location.pathname}?bookId=${dataValue.bookId}&requestId=${dataValue.requestId}&type=${typeValue}`;
      // window.location.href = url;
      window.history.replaceState({}, '', url);
    }
  }, [dataValue]);

  const handleEvent = async (params, index) => {
    const { requestId, bookId, type, audioIntent } = params;
    setRowDialogue(params);
    openChatDialog(true);
    setModalIndex(index);
    setSelectedValue(index);
    if (isFeedback) {
      let typeValue = type;
      setDataValue(params);
      if (
        [
          constants.FEEDBACK_EXPLAIN_TYPE,
          constants.FEEDBACK_QUIZ_TYPE,
          constants.SUMMARY_TYPE,
          constants.IMAGE_EXPLAIN,
          constants.IMAGE_ASK_QUESTION,
          constants.IMAGE_CYU_EVALUATE
        ].includes(typeValue)
      ) {
        await chatUtilityGetTypes.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
      } else if (typeValue === constants.FEEDBACK_QUIZ_TYPE) {
        typeValue = constants.QUIZ_MCQ_TYPE;
        chatgptResponseUtility.fetch({ requestId, bookId, type: typeValue });
      } else {
        chatgptResponseUtility.fetch({ requestId, bookId, type: typeValue });
      }
    } else {
      if (audioIntent) {
        chatgptResponseUtility.fetch({ requestId, bookId, audioIntent });
      } else {
        chatgptResponseUtility.fetch({ requestId, bookId, type });
      }

      if (type && type === constants.PROBLEM_SOLVE) {
        await chatUtilityGetTypes.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
      }
    }
  };

  useEffect(() => {
    // Function to check URL parameters
    const checkUrlParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const bookId = urlParams.get('bookId');
      const requestId = urlParams.get('requestId');
      const type = urlParams.get('type');

      if (bookId && requestId && type) {
        setDatalength(1);
        handleEvent({ bookId, requestId, type }, 0);
      }
    };
    if (!isFeedback) {
      checkUrlParams();
    }
  }, []);

  const handleClose = () => {
    if (!isFeedback) {
      const url = `${window.location.origin}${window.location.pathname}`;
      window.history.replaceState({}, '', url);
    }
    openChatDialog(false);
    setModalIndex(1);
    setDataValue({});
  };

  const handleModalChange = async (value) => {
    let modalArrData = chatgptUtilityData;
    if (isUserIdExcluded && internalUserIds?.userId.length > 0) {
      modalArrData = filteredArray;
    }
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, bookId, type, audioIntent } = modalArrData[modalIndex - 1];
      setRowDialogue(modalArrData[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
      if (isFeedback) {
        if (
          (type && type === constants.PROBLEM_SOLVE) ||
          [
            constants.FEEDBACK_EXPLAIN_TYPE,
            constants.FEEDBACK_QUIZ_TYPE,
            constants.SUMMARY_TYPE,
            constants.IMAGE_EXPLAIN,
            constants.IMAGE_ASK_QUESTION,
            constants.IMAGE_CYU_EVALUATE
          ].includes(type)
        ) {
          await chatUtilityGetTypes.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
        } else {
          let typeValue = type;
          if (typeValue === constants.FEEDBACK_QUIZ_TYPE) {
            typeValue = constants.QUIZ_MCQ_TYPE;
          }
          chatgptResponseUtility.fetch({ requestId, bookId, type: typeValue });
        }
        setDataValue(modalArrData[modalIndex - 1]);
      } else {
        if (audioIntent) {
          chatgptResponseUtility.fetch({ requestId, bookId, audioIntent });
        } else {
          chatgptResponseUtility.fetch({ requestId, bookId, type });
        }
        if (type && type === constants.PROBLEM_SOLVE) {
          await chatUtilityGetTypes.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
        }
      }
    } else {
      const { requestId, bookId, type, audioIntent } = modalArrData[modalIndex + 1];
      setRowDialogue(modalArrData[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
      if (isFeedback) {
        let typeValue = type;
        if (
          [
            constants.FEEDBACK_EXPLAIN_TYPE,
            constants.FEEDBACK_QUIZ_TYPE,
            constants.SUMMARY_TYPE,
            constants.IMAGE_EXPLAIN,
            constants.IMAGE_ASK_QUESTION,
            constants.IMAGE_CYU_EVALUATE
          ].includes(type)
        ) {
          await chatUtilityGetTypes.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
        } else if (typeValue === constants.FEEDBACK_QUIZ_TYPE) {
          typeValue = constants.QUIZ_MCQ_TYPE;
          chatgptResponseUtility.fetch({ requestId, bookId, type: typeValue });
        } else {
          chatgptResponseUtility.fetch({ requestId, bookId, type: typeValue });
        }
        setDataValue(modalArrData[modalIndex + 1]);
      } else {
        if (audioIntent) {
          chatgptResponseUtility.fetch({ requestId, bookId, audioIntent });
        } else {
          chatgptResponseUtility.fetch({ requestId, bookId, type });
        }
        if (type && type === constants.PROBLEM_SOLVE) {
          await chatUtilityGetTypes.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
        }
      }
    }
  };

  const handlePageChange = (e) => {
    e.preventDefault();
    const { isNextPageKey } = props;
    let lastKey = {};
    if (isNextPageKey && isNextPageKey.ExclusiveStartKey) {
      const startKey = isNextPageKey.ExclusiveStartKey;
      lastKey = Object.entries(startKey).reduce((obj, [key, value]) => {
        if (key !== null && value !== null) {
          obj[key] = value;
        }
        return obj;
      }, {});
    }
    if (isNextPageKeyEnable?.ExclusiveStartKey?.bookId) {
      handleSearch({ lastKey: { ExclusiveStartKey: isNextPageKeyEnable } });
    } else {
      handleSearch({ lastKey: { ExclusiveStartKey: lastKey } });
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((data) => data.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
      }
    })
  );

  const handleUserIdCheck = (item, checkedData) => {
    setUserId(item, checkedData);
  };

  const aiStudyToolsAuditHeaders = [
    {
      key: 'serverDateTime',
      name: `${constants.DATE_TIME} (GMT)`
    },
    {
      key: 'bookId',
      name: constants.BOOK_ID
    },
    {
      key: 'title',
      name: constants.TITLE
    },
    {
      key: 'type',
      name: constants.TYPE
    },
    {
      key: 'question',
      name: 'User Input'
    },
    {
      key: 'userId',
      name: constants.USER_ID
    },
    ...(isAudioIntentSelected ? [{ key: 'audioIntent', name: 'Audio Intent' }] : [])
  ];
  const aiStudyFeedbackHeaders = [
    {
      key: 'serverDateTime',
      name: `${constants.DATE_TIME} (GMT)`
    },
    {
      key: 'bookId',
      name: constants.BOOK_ID
    },
    {
      key: 'title',
      name: constants.TITLE
    },
    {
      key: 'feedback',
      name: 'Feedback'
    },
    {
      key: 'reasonForDisLike',
      name: 'Reason for dislike'
    },
    {
      key: 'comments',
      name: 'Comments'
    },
    {
      key: 'type',
      name: constants.TYPE
    },
    {
      key: 'userId',
      name: constants.USER_ID
    }
  ];

  return (
    <>
      <>
        <ExportToCSV
          headers={isFeedback ? aiStudyFeedbackHeaders : aiStudyToolsAuditHeaders}
          data={filteredArray}
          fileTitle={isFeedback ? 'AIStudyFeedbackLogs' : 'AIStudyAuditLogs'}
        />
        <TableContainer className={classes.root} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableCustomWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    {constants.SELECT}
                  </Typography>
                </TableCell>
                <TableCell align="center" sortDirection="asc" className={classes.tableDateWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    {`${constants.DATE_TIME} (GMT)`}
                    <TableSortLabel
                      onClick={() => sorting('serverDateTime')}
                      active
                      direction={directionValue === 'serverDateTime' && order === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableBookWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    {constants.BOOK_ID}
                    <TableSortLabel
                      active
                      direction={directionValue === 'bookId' && order === 'DSC' ? 'asc' : 'desc'}
                      onClick={() => sorting('bookId')}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.tableTitleWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    {constants.TITLE}
                    <TableSortLabel
                      active
                      direction={directionValue === 'title' && order === 'DSC' ? 'asc' : 'desc'}
                      onClick={() => sorting('title')}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                {isFeedback ? (
                  <TableCell align="center" className={classes.tableFeedWidth}>
                    <Typography variant="h6" className={classes.columnStyle}>
                      Feedback
                      <TableSortLabel
                        onClick={() => sorting('feedback')}
                        direction={directionValue === 'feedback' && order === 'DSC' ? 'asc' : 'desc'}
                        active
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Typography>
                  </TableCell>
                ) : null}
                {isFeedback ? (
                  <TableCell align="center" className={classes.tableReasonWidth}>
                    <Typography variant="h6" className={classes.columnStyle}>
                      Reason for dislike
                      <TableSortLabel
                        onClick={() => sorting('comments')}
                        direction={directionValue === 'comments' && order === 'DSC' ? 'asc' : 'desc'}
                        active
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Typography>
                  </TableCell>
                ) : null}
                {isFeedback ? (
                  <TableCell align="center" className={classes.tableCommentsWidth}>
                    <Typography variant="h6" className={classes.columnStyle}>
                      Comments
                      <TableSortLabel
                        onClick={() => sorting('comments')}
                        direction={directionValue === 'comments' && order === 'DSC' ? 'asc' : 'desc'}
                        active
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Typography>
                  </TableCell>
                ) : null}
                {!isAudioIntentSelected ? (
                  <TableCell align="center" className={classes.tableTypeWidth}>
                    <Typography
                      variant="h6"
                      className={classes.tableTypeText}
                      // style={{ fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif', fontWeight: '600' }}
                    >
                      {constants.TYPE}
                      <TableSortLabel
                        active
                        direction={directionValue === 'type' && order === 'DSC' ? 'asc' : 'desc'}
                        onClick={() => sorting('type')}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Typography>
                  </TableCell>
                ) : null}
                <TableCell align="center" className={classes.tableTitleWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    Audio Intent
                    <TableSortLabel
                      active
                      direction={directionValue === 'audioIntent' && order === 'DSC' ? 'asc' : 'desc'}
                      onClick={() => sorting('audioIntent')}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
                {!isFeedback && (
                  <TableCell align="center" className={classes.tableUserWidth}>
                    <Typography variant="h6" className={classes.columnStyle}>
                      User Input
                      <TableSortLabel
                        active
                        direction={directionValue === 'question' && order === 'DSC' ? 'asc' : 'desc'}
                        onClick={() => sorting('question')}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Typography>
                  </TableCell>
                )}
                <TableCell align="center" className={classes.tableUserIdWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    {constants.USER_ID}
                    <TableSortLabel
                      active
                      direction={directionValue === 'userId' && order === 'DSC' ? 'asc' : 'desc'}
                      onClick={() => sorting('userId')}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredArray &&
                filteredArray.map((data, index) => {
                  return (
                    <>
                      <TableRow onClick={() => handleEvent(data, index)}>
                        <TableCell className={classes.radioButton}>
                          <Radio size="small" checked={selectedValue === index} color="primary" name="radioButton" />
                        </TableCell>
                        <TableCell className={classes.tableFilterDateWidth}>
                          <Typography className={classes.tableText}>{data.serverDateTime}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText}> {data.bookId}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableFilterTextWidth}>
                          <Typography className={classes.tableText}> {data.title}</Typography>
                        </TableCell>
                        {isFeedback ? (
                          <TableCell className={classes.tableFilterFeedWidth}>
                            <Typography className={classes.tableText}> {data.feedback}</Typography>
                          </TableCell>
                        ) : null}
                        {isFeedback ? (
                          <TableCell className={classes.tableFilterReasonWidth}>
                            <Typography className={classes.tableText}>
                              {' '}
                              {data.reasonForDisLike.map((value, index) => (
                                <span>
                                  {value}
                                  {index !== data.reasonForDisLike.length - 1 && <b>{' | '}</b>}
                                </span>
                              ))}
                            </Typography>
                          </TableCell>
                        ) : null}
                        {isFeedback ? (
                          <TableCell>
                            <Typography className={classes.tableTextAlongWidth}> {data.comments}</Typography>
                          </TableCell>
                        ) : null}
                        {!isAudioIntentSelected ? (
                          <TableCell>
                            <Typography className={classes.tableText}>
                              {' '}
                              {data.type === 'answer' || data.type === 'discuss' ? 'explain' : data.type}
                            </Typography>
                          </TableCell>
                        ) : null}
                        <TableCell className={classes.tableFilterTextWidth}>
                          {data?.audioIntent && <Typography className={classes.tableText}> True</Typography>}
                        </TableCell>
                        {!isFeedback && (
                          <TableCell>
                            <Typography className={classes.tableTextAlongWidth}>
                              {([
                                constants.CHAT_UTILITY_ANSWER,
                                constants.DISCUSS,
                                constants.CHAT_INTENT,
                                constants.PROBLEM_SOLVE,
                                constants.PROBLEM_SOLVE_RECTIFIER,
                                constants.SUMMARY_EXPLAIN,
                                constants.CONTEXT_IDENTIFIER_USER_SELECTION,
                                constants.EXPLAIN_STAND_ALONE,
                                constants.SALUTATION,
                                constants.TOPIC,
                                constants.TYPE_QUIZ,
                                constants.SUMMARY_TYPE,
                                constants.QUIZ_MCQ_TYPE,
                                constants.SEARCH_OVERVIEW_TYPE,
                                constants.QUIZ_INTENT,
                                constants.IMAGE_COPILOT_ANSWER,
                                constants.IMAGE_COPILOT_CYU,
                                constants.IMAGE_COPILOT_CYU_EVALUATE,
                                constants.IMAGE_COPILOT_EXPLAIN,
                                constants.IMAGE_COPILOT_INTENT_CLASSIFIER
                              ].includes(data.type) &&
                                data.type !== data.question) ||
                              (data?.audioIntent &&
                                [constants.SUMMARY_TYPE, constants.QUIZ_MCQ_TYPE].includes(data.type))
                                ? data.question
                                : ''}
                            </Typography>
                          </TableCell>
                        )}
                        {/* {!isFeedback ? ( */}
                        <TableCell
                          className={classes.tableFilterTooltipWidth}
                          onMouseEnter={() => hanldeGETUserId(data.userId)}
                          key={index}
                        >
                          <LightTooltip
                            title={
                              userEmailDetails
                                ? Object.keys(userEmailDetails).map((item) => {
                                    let poperValue = <></>;
                                    if (item === 'firstName') {
                                      poperValue = (
                                        <Typography
                                          gutterBottom
                                          className={classes.tableFilterTooltextWidth}
                                          // style={{
                                          //   fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
                                          //   color: '#252525'
                                          // }}
                                        >
                                          <b>First Name : </b>

                                          {userEmailDetails[item]}
                                        </Typography>
                                      );
                                    } else if (item === 'lastName') {
                                      poperValue = (
                                        <Typography gutterBottom>
                                          <b>Last Name : </b>

                                          {userEmailDetails[item]}
                                        </Typography>
                                      );
                                    } else if (item === 'email') {
                                      poperValue = (
                                        <Typography gutterBottom>
                                          <b>Email : </b>

                                          {userEmailDetails[item]}
                                        </Typography>
                                      );
                                    } else if (item === 'userId') {
                                      poperValue = (
                                        <Typography gutterBottom>
                                          <b>Filter results by this User Id : </b>
                                          <Checkbox
                                            defaultChecked
                                            checked={userIdChecked}
                                            onChange={() => handleUserIdCheck(item, data.userId)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="default"
                                            sx={{
                                              color: 'blue',
                                              '&.Mui-checked': {
                                                color: 'blue'
                                              }
                                            }}
                                          />
                                          {/* {userEmailDetails[item]} */}
                                        </Typography>
                                      );
                                    }
                                    return poperValue;
                                  })
                                : 'Loading Data ...'
                            }
                          >
                            <Typography className={classes.tableText}> {data.userId}</Typography>
                          </LightTooltip>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Box spacing={2}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
            {((isNextPageKey &&
              isNextPageKey.ExclusiveStartKey &&
              isNextPageKey.ExclusiveStartKey.bookId &&
              isNextPageKey.ExclusiveStartKey.bookId.S !== null) ||
              (isNextPageKeyEnable &&
                isNextPageKeyEnable.ExclusiveStartKey &&
                isNextPageKeyEnable.ExclusiveStartKey.bookId &&
                isNextPageKeyEnable.ExclusiveStartKey.bookId !== null)) && (
              <Box>
                <ThemeProvider theme={themes}>
                  <Button
                    size="small"
                    color="primary"
                    className={classes.buttonStyle}
                    variant="contained"
                    onClick={(e) => handlePageChange(e)}
                  >
                    <Tooltip title="Load next set of data">Load More</Tooltip>
                  </Button>
                </ThemeProvider>
              </Box>
            )}
          </Stack>
        </Box>

        {openChat && (
          <ChatDialogComponent
            openChat={openChat}
            dataValue={dataValue}
            userTocDetails={userTocDetails}
            dataLength={dataLength}
            modalIndex={modalIndex}
            filterPromt={constants.FILTER_PROMPT}
            isFeedback={isFeedback}
            loaderStatusResponse={loaderStatusResponse}
            chatgptUtilityResponseStatus={chatgptUtilityResponseStatus}
            handleClose={handleClose}
            handleModalChange={handleModalChange}
            problemSolveFlow={problemSolveFlow}
          />
        )}
      </>
    </>
  );
}

ChatDataGrid.propTypes = {
  chatgptUtilityData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleAscending: PropTypes.func.isRequired,
  isNextPageKey: PropTypes.object,
  isFeedback: PropTypes.bool.isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptMappingUtility: shapes.modelOf(ChatgptUtilityMapping).isRequired,
  chatUtilityGetTypes: shapes.modelOf(ChatUtilityGetTypesModel).isRequired,
  isNextPageKeyEnable: PropTypes.object,
  setUserId: PropTypes.func.isRequired,
  userIdChecked: PropTypes.bool.isRequired,
  isAudioIntentSelected: PropTypes.bool.isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired,
  internalUsers: shapes.modelOf(InternalUsersList).isRequired
};

ChatDataGrid.defaultProps = {
  isNextPageKey: {},
  isNextPageKeyEnable: {}
};

export default withStyles(styles)(
  observer(
    inject(
      'chatgptResponseUtility',
      'chatgptUtilityResponseStatus',
      'ChatgptUtilityIESUserData',
      'chatgptMappingUtility',
      'chatUtilityGetTypes',
      'internalUsers'
    )(ChatDataGrid)
  )
);
